import { Routes, Route, BrowserRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import routes from './routes';

function App() {
  return <BrowserRouter>
    <Routes>
      {routes.map(route => (
        <Route key={route.path} path={route.path} element={route.element} />
      ))}
      <Route path="/:userlink" element={<Home download />} />
    </Routes>
  </BrowserRouter>;
}

export default App;
