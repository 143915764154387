import { useEffect, useLayoutEffect } from "react";
import { getMobileOperatingSystem } from "../helpers";

const Home = (props: { download?: boolean }) => {

    useLayoutEffect(() => {
        const resize = () => {
            document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
        }
        resize();
        const resizeListener = window.addEventListener('resize', resize);
        return () => { window.removeEventListener('resize', resize); };
    }, []);

    useEffect(() => {
        if (props.download) setTimeout(() => {
            const os = getMobileOperatingSystem();
            if (os === 'ios') window.location.replace('https://apple.co/3ENa4au');
            if (os === 'android') window.location.replace('https://play.google.com/store/apps/details?id=com.prezle.app');
        }, 300);
    }, []);

    return <div className="App">
    <img className="icon" src="./assets/prezle-icon-square.svg" />
    <img className="logo" src="./assets/prezle-text.svg" />
    <span className='coming-soon'>Swipe. Match. Wrap.</span>
    <span className="subtitle">The app that makes present finding fun, quick and social.</span>
    <div className="download-logos">
    <img className="apple-download" src="./assets/apple-download.svg" onClick={() => {window.location.href = 'https://apple.co/3ENa4au'}}/>
    <img className="google-download" src="./assets/google-play-badge.png" onClick={() => {window.location.href = 'https://play.google.com/store/apps/details?id=com.prezle.app'}}/>
    </div>
    <div style={{display: 'flex', gap: 10, color: '#444'}}><a href="./privacy-policy.html">Privacy Policy</a> • <a href="./support">Support</a></div>
    </div> 
}

export default Home;