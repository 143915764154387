import { ReactElement } from "react";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Support from "./pages/Support";

export interface route {
    path: string;
    title: string;
    element: ReactElement;
}

const routes: route[] = [
    {
        path: '/',
        title: 'Prezle',
        element: <Home />
    },
    {
        path: '/privacy',
        title: 'Privacy Policy',
        element: <PrivacyPolicy />
    },
    {
        path: '/support',
        title: 'Support',
        element: <Support />
    },
    {
        path: '/download',
        title: 'Download',
        element: <Home download />
    }
]

export default routes;